import { compose } from 'redux';

import Typography from '@material-ui/core/Typography';
import { withStyles, withTheme } from '@material-ui/core/styles';

import { withProps } from 'libraries/components';
import { mobileAppContent } from 'models/content';
import styles from './styles';

const AppDownload = ({
    classes,
    mobileAppContent,
}) => (
    <div className={classes.root}>
        <div className={classes.content}>
            <img
                className={classes.screenshot}
                src={mobileAppContent.screenshot}
                alt="screenshot"
            />
            <div className={classes.whiteBack} />
            <div className={classes.blackBack} />
            <div className={classes.textContainer}>
                <Typography variant="h2" className={classes.text}>
                    {mobileAppContent.title}
                </Typography>
                <div className={classes.badgeContainer}>
                    <img
                        className={classes.appleBadge}
                        src={mobileAppContent.onAppStore}
                        alt="available on appstore"
                    />
                    <img
                        className={classes.googleBadge}
                        src={mobileAppContent.onGooglePlay}
                        alt="available on google play"
                    />
                </div>
            </div>
            <div className={classes.polygon} />
        </div>
    </div>
);

export default compose(
    withStyles(styles),
    withTheme,
    withProps({ mobileAppContent }),
)(AppDownload);
