const styles = (theme) => ({
    root: {
        height: '12em',
        backgroundColor: theme.palette.primary.dark,
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
    },
    text: {
        color: theme.palette.primary.contrastText,
    },
    toolbar: {
        height: '100%',
    },
    logo: {
        width: '4em',
        margin: '0 auto 12px',
        display: 'block',
    },
    policyContainer: {
        textAlign: 'center',
        paddingTop: theme.spacing(3),
    },
    policyLink: {
        color: '#fff',
        padding: `0 ${theme.spacing(3)}px`,
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
            cursor: 'pointer',
        },
    },
    link: {
        textDecoration: 'none',
    },
});

export default styles;
