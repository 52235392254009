import firebase from 'firebase/app';
import 'firebase/performance';
import 'firebase/firestore';
import 'firebase/auth';

import firebaseConfig from './config';

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const auth = firebase.auth();

const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const phoneAuthProvider = firebase.auth.PhoneAuthProvider.PROVIDER_ID;
const appleAuthProvider = new firebase.auth.OAuthProvider('apple.com');

appleAuthProvider.addScope('email');
appleAuthProvider.addScope('name');

export {
    firebase,
    db,
    auth,
    facebookAuthProvider,
    phoneAuthProvider,
    appleAuthProvider,
};
export default firebase;
