import { compose } from 'redux';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import { withProps } from 'libraries/components';
import { userInfo } from 'models/user';

import styles from './styles';
import { withContent } from '../../../../components';
import { profile } from '../../../../models/content';

const UserBio = ({
    classes,
    userInfo,
    title,
}) => (
    userInfo?.reviewed ? (
        <Paper className={classes.infoContainer}>
            <Typography variant="h4">
                { title }
            </Typography>
            <Typography>
                {userInfo?.bio}
            </Typography>
        </Paper>
    ) : null
);

export default compose(
    withStyles(styles),
    withProps({ userInfo }),
    withContent('title', profile, 'bio_title', 'Bio'),
)(UserBio);
