import { actionCreator, effectActionCreator } from 'libraries/system';

const setDeviceInfo = actionCreator('SET_DEVICE_INFO');

const openMissingInfoDialog = actionCreator('OPEN_MISSING_INFO_DIALOG');
const closeMissingInfoDialog = actionCreator('CLOSE_MISSING_INFO_DIALOG');

const acceptCookies = effectActionCreator('ACCEPT_COOKIES');

export {
    setDeviceInfo,
    openMissingInfoDialog,
    closeMissingInfoDialog,
    acceptCookies,
};
