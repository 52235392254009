import { useSelector } from 'react-redux';

import { get, identity } from 'lodash';

const useContent = (contentSelector, path, defaultContent = '', modifier = identity) => {
    const story = useSelector(contentSelector);

    return modifier(path ? get(story, path, defaultContent) : story);
};

export default useContent;
