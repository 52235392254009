const styles = () => ({
    mobilePadding: {
        paddingTop: '2em',
    },
    mobileActionPadding: {
        paddingBottom: '4em',
    },
});

export default styles;
