import { actionCreator } from 'libraries/system';

const sendNotification = actionCreator('SEND_NOTIFICATION');
const closeNotification = actionCreator('CLOSE_NOTIFICATION');
const removeNotification = actionCreator('REMOVE_NOTIFICATION');

export {
    sendNotification,
    removeNotification,
    closeNotification,
};
