import { compose } from 'redux';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import { withContent } from 'components';
import { home } from 'models/content';

import { Step } from './components';
import styles from './styles';

const Process = ({ classes, title, steps }) => (
    <>
        <Typography className={classes.title} variant="h3">
            { title }
        </Typography>
        {
            steps.map((step, i) => (
                <Step
                    className={classes.step}
                    title={step?.title}
                    body={step?.body}
                    image={step?.image.filename}
                    rtl={i % 2 === 0}
                    key={step?.title}
                />
            ))
        }
    </>
);

export default compose(
    withStyles(styles),
    withContent('title', home, 'steps_title', 'steps_title'),
    withContent('steps', home, 'steps', []),
)(Process);
