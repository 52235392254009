import { compose } from 'redux';

import Button from '@material-ui/core/Button';
import MuiDialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';

import { withProps } from 'libraries/components';
import { missingInfoDialogOpen, closeMissingInfoDialog, deviceInfo } from 'models/ui';
import styles from './styles';

const Dialog = ({
    classes,
    missingInfoDialogOpen,
    closeMissingInfoDialog,
    deviceInfo,
    onSubmit,
    children,
}) => (
    <MuiDialog
        open={missingInfoDialogOpen}
        fullScreen={deviceInfo.platform !== 'web'}
        onClose={closeMissingInfoDialog}
    >
        <DialogTitle className={deviceInfo.platform !== 'web' ? classes.mobilePadding : ''}>
            Your Details
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                Please fill the missing information and make sure everything is up to date.
            </DialogContentText>
            { children }
        </DialogContent>
        <DialogActions className={deviceInfo.platform !== 'web' ? classes.mobileActionPadding : ''}>
            <Button onClick={closeMissingInfoDialog} color="primary">
                Cancel
            </Button>
            <Button
                onClick={() => { onSubmit(); closeMissingInfoDialog(); }}
                color="primary"
                variant="contained"
            >
                Submit
            </Button>
        </DialogActions>
    </MuiDialog>
);

export default compose(
    withStyles(styles),
    withProps({ missingInfoDialogOpen, closeMissingInfoDialog, deviceInfo }),
)(Dialog);
