import { actionCreator, effectActionCreator } from 'libraries/system';

const setLanguage = actionCreator('SET_LANGUAGE');
const setAvailableLanguages = actionCreator('SET_AVAILABLE_LANGUAGES');

const loadHomeContent = effectActionCreator('LOAD_HOME_CONTENT');
const loadRegisterContent = effectActionCreator('LOAD_REGISTER_CONTENT');
const loadPoliciesContent = effectActionCreator('LOAD_POLICIES_CONTENT');

const setHomeContent = actionCreator('SET_HOME_CONTENT');
const setProfileContent = actionCreator('SET_PROFILE_CONTENT');

export {
    loadHomeContent,
    loadRegisterContent,
    setHomeContent,
    setLanguage,
    loadPoliciesContent,
    setAvailableLanguages,
    setProfileContent,
};
