import { compose } from 'redux';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';

import { ParallaxProvider, Parallax } from 'react-scroll-parallax';

import { withMobileRedirect } from 'utils';
import {
    Header,
    About,
    Process,
    JoinBanner,
    MobileApp,
} from './components';
import styles from './styles';

const Home = ({ classes }) => (
    <ParallaxProvider>
        <Header />
        <Container className={classes.container} maxWidth={false}>
            <Parallax y={[60, -100]}>
                <About />
            </Parallax>
            <Process />
        </Container>
        <div className={classes.spacer} />
        <JoinBanner />
        <MobileApp />
    </ParallaxProvider>
);

export default compose(
    withStyles(styles),
    withMobileRedirect('/sign-on'),
)(Home);
