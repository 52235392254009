import { compose } from 'redux';

import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

import { withProps } from 'libraries/components';
import { user, userInfo } from 'models/user';

import {
    UserHeader,
    PendingReview,
    UserBio,
    MissingInfo,
} from './components';
import styles from './styles';

const MyProfile = ({
    classes,
    user,
    userInfo,
}) => (user && userInfo
    ? (
        <Container maxWidth="md">
            <UserHeader />
            <UserBio />
            <MissingInfo />
            <PendingReview />
        </Container>
    ) : (
        <CircularProgress className={classes.spinner} />
    )
);

export default compose(
    withStyles(styles),
    withProps({ user, userInfo }),
)(MyProfile);
