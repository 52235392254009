import { createReducer } from '@reduxjs/toolkit';
import {
    WITH_EMAIL,
    WITH_PHONE,
    EMAIL_OR_PHONE,
    PASSWORD_OR_PIN,
} from '@grandmama/ui-login-form';
import * as actions from './actions';

const initialState = {
    user: null,
    userInfo: null,
    userInfoMissing: false,
    signInChecked: false,
    recaptchaSolvedResult: false,
    phoneVerifier: null,
    signOnForm: {
        step: EMAIL_OR_PHONE,
        userCheckResults: {},
        loading: false,
        logInType: WITH_EMAIL,
    },
};

const reducer = createReducer(initialState, (builder) => builder
    .addCase(actions.signIn.succeeded, (state, { payload }) => {
        state.user = payload;
        state.signInChecked = true;
    })
    .addCase(actions.signIn.failed, (state) => {
        state.signInChecked = true;
    })
    .addCase(actions.signOut, (state) => {
        state.user = null;
    })
    .addCase(actions.setUserInfo, (state, { payload }) => {
        state.userInfo = payload;
    })
    .addCase(actions.checkUser, (state) => {
        state.signOnForm.loading = true;
    })
    .addCase(actions.checkUser.succeeded, (state, { payload }) => {
        state.signOnForm.loading = false;
        state.signOnForm.userCheckResults = {
            ...payload,
            existingUser: payload.userExists,
            logInType: payload.signInMethod === 'email' ? WITH_EMAIL : WITH_PHONE,
        };
        state.signOnForm.step = PASSWORD_OR_PIN;
    })
    .addCase(actions.checkUser.failed, (state) => {
        state.signOnForm.loading = false;
    })
    .addCase(actions.recaptchaSolved, (state) => {
        state.recaptchaSolvedResult = true;
    })
    .addCase(actions.storeVerifier, (state, { payload }) => {
        state.phoneVerifier = payload;
    })
    .addCase(actions.singOnPreviousStep, (state) => {
        state.signOnForm.step = state.signOnForm.step === PASSWORD_OR_PIN
            ? EMAIL_OR_PHONE
            : PASSWORD_OR_PIN;
    })
    .addCase(actions.toggleActive.succeeded, (state) => {
        state.userInfo.available = !state.userInfo.available;
    })
    .addCase(actions.setUserInfoMissing, (state, { payload }) => {
        state.userInfoMissing = payload;
    }));

export default reducer;
