import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography';

const Policy = ({ policy }) => (typeof policy === 'string'
    ? (
        <DialogContentText>
            { policy }
        </DialogContentText>
    )
    : policy.map(({ _uid, title, body }) => (
        <div key={_uid}>
            <Typography variant="h6">
                { title }
            </Typography>
            <DialogContentText>
                { body }
            </DialogContentText>
        </div>
    )));

export default Policy;
