import { compose } from 'redux';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { Form } from 'react-final-form';

import { withProps } from 'libraries/components';
import { openMissingInfoDialog } from 'models/ui';
import { userInfoMissing, userInfo, addMissingInfo } from 'models/user';
import { profile } from 'models/content';
import { withContent } from 'components';

import { Dialog, InfoForm, withFormValidation } from './components';
import styles from './styles';

const MissingInfo = ({
    classes,
    userInfoMissing,
    openMissingInfoDialog,
    userInfo,
    addMissingInfo,
    validate,
    missingInfo,
    completeApplication,
    missingImage,
}) => (
    userInfoMissing && (
        <div className={classes.root}>
            <img className={classes.image} src={missingImage} alt={missingInfo} />
            <Typography className={classes.title} variant="h4">
                { missingInfo }
            </Typography>
            <Button
                variant="contained"
                color="primary"
                onClick={openMissingInfoDialog}
            >
                { completeApplication }
            </Button>
            <Form
                onSubmit={(v) => addMissingInfo(v)}
                initialValues={userInfo}
                validate={validate}
                render={({ handleSubmit }) => (
                    <Dialog onSubmit={handleSubmit}>
                        <InfoForm onSubmit={handleSubmit} />
                    </Dialog>
                )}
            />
        </div>
    )
);

export default compose(
    withStyles(styles),
    withProps({
        userInfoMissing,
        openMissingInfoDialog,
        userInfo,
        addMissingInfo,
    }),
    withFormValidation,
    withContent('missingInfo', profile, 'missing_info', 'Some information is missing.'),
    withContent('completeApplication', profile, 'complete_application', 'Complete Application'),
    withContent('missingImage', profile, 'missing_image.filename'),
)(MissingInfo);
