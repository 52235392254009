import { compose } from 'redux';
import Typography from '@material-ui/core/Typography';
import { GraphicBox } from '@grandmama/ui-graphic-box';
import { withStyles } from '@material-ui/core/styles';
import { grandma } from 'assets';

import { home } from 'models/content';
import { withContent } from 'components';
import styles from './styles';

const About = ({
    classes,
    image,
    title,
    body,
}) => (
    <>
        <GraphicBox
            graphic={(
                <img
                    className={classes.graphic}
                    src={image}
                    alt="hero"
                />
            )}
            paperProps={{
                className: classes.paper,
            }}
        >
            <Typography variant="h4">
                {title}
            </Typography>
            <br />
            <br />
            <Typography>
                {body}
            </Typography>
        </GraphicBox>
    </>
);

export default compose(
    withStyles(styles),
    withContent('image', home, 'about.[0].image.filename', grandma),
    withContent('title', home, 'about.[0].title', 'Do your best work.'),
    withContent('body', home, 'about.[0].body', ''),
)(About);
