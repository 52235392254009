const styles = (theme) => ({
    root: {
        height: '100vh',
        position: 'relative',
        width: '100%',
        paddingTop: theme.spacing(12),
        [theme.breakpoints.down('sm')]: {
            height: '120vh',
        },
        [theme.breakpoints.down('xs')]: {
            height: '140vh',
            minHeight: 1200,
        },
    },
    polygon: {
        height: '100%',
        width: '100%',
        backgroundColor: theme.palette.primary.main,
        position: 'absolute',
        top: 0,
        left: 0,
        mixBlendMode: 'screen',
        zIndex: 4,
    },
    content: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        top: 0,
        left: 0,
        zIndex: 100,
    },
    whiteBack: {
        background: 'white',
        position: 'absolute',
        height: '100%',
        width: '100%',
        zIndex: 1,
    },
    blackBack: {
        background: 'black',
        position: 'absolute',
        zIndex: 2,
        clipPath: 'polygon(0 0, 100% 82%, 100% 100%, 0% 100%)',
        height: '100%',
        width: '100%',
    },
    screenshot: {
        position: 'relative',
        width: '30em',
        float: 'right',
        marginRight: '12%',
        marginTop: theme.spacing(12),
        zIndex: 100,
        [theme.breakpoints.down('md')]: {
            marginRight: '6%',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'block',
            margin: '0 auto',
            width: '60%',
            zIndex: 1000,
            float: 'left',
            left: 'calc(50vw - 30%)',
            top: '26vh',
        },
        [theme.breakpoints.down('xs')]: {
            display: 'block',
            margin: '0 auto',
            width: '60%',
            zIndex: 1000,
            float: 'left',
            left: 'calc(50vw - 30%)',
            top: '30%',
        },
    },
    textContainer: {
        position: 'absolute',
        top: 50,
        zIndex: 3,
        mixBlendMode: 'difference',
        color: 'white',
        [theme.breakpoints.down('sm')]: {
            top: 10,
        },
    },
    text: {
        width: '70%',
        padding: theme.spacing(12),
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            padding: theme.spacing(6),
        },
    },
    badgeContainer: {
        marginTop: theme.spacing(4),
        padding: theme.spacing(12),
        [theme.breakpoints.down('sm')]: {
            margin: '0 auto',
            marginTop: theme.spacing(3),
            width: 'fit-content',
            position: 'relative',
            bottom: 'calc(-1 * (100vh - 328px))',
            padding: 0,
        },
        [theme.breakpoints.down('xs')]: {
            margin: '0 auto',
            marginTop: theme.spacing(3),
            width: 'fit-content',
            position: 'relative',
            bottom: 'calc(-1 * (100vh - 328px))',
            padding: 0,
        },
    },
    appleBadge: {
        width: 140,
        paddingBottom: 12,
    },
    googleBadge: {
        width: 180,
    },
});

export default styles;
