import { useEffect, useRef } from 'react';
import { firebase } from 'services/firebaseDB';

import { withProps } from 'libraries/components';
import { recaptchaSolved } from 'models/user';
import { deviceInfo } from 'models/ui';

import useStyles from './styles';

const Recaptcha = ({ recaptchaSolved, deviceInfo }) => {
    const classes = useStyles();
    const recaptchaRef = useRef(null);

    useEffect(() => {
        if (deviceInfo.platform === 'ios') {
            recaptchaSolved();
            return;
        }
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(recaptchaRef.current, {
            size: 'small',
            callback: () => {
                recaptchaSolved();
            },
        });

        window.recaptchaVerifier.render().then((widgetId) => {
            window.recaptchaWidgetId = widgetId;
        });
    }, []);

    return deviceInfo.platform !== 'ios' ? (
        <div className={classes.container} id="recaptcha" ref={recaptchaRef} />
    ) : null;
};

export default withProps({ recaptchaSolved, deviceInfo })(Recaptcha);
