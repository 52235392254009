import { Observable } from 'rxjs';

import StoryblokClient from 'storyblok-js-client';

import config from './config';

const Storyblok = new StoryblokClient(config);

const Story$ = (path) => new Observable(
    (observer) => Storyblok
        .get(path, {})
        .then(({ data }) => observer.next(data))
        .catch((error) => observer.error(error))
        .finally(() => observer.complete()),
);

export default Story$;
