const styles = (theme) => ({
    spacer: {
        height: theme.spacing(2),
    },
    button: {
        marginBottom: theme.spacing(2),
    },
    divider: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
});

export default styles;
