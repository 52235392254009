import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({
    isAuthenticated,
    redirectTo,
    children,
    ...rest
}) => (
    <Route
        {...rest}
        render={({ location }) => (isAuthenticated ? (
            children
        ) : (
            <Redirect
                to={{
                    pathname: redirectTo || '/sign-in',
                    state: { from: location },
                }}
            />
        ))}
    />
);

export default PrivateRoute;
