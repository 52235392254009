import { createSelector } from 'reselect';
import { startCase, toLower } from 'lodash';

const home = ({ content }) => content.home;
const profile = ({ content }) => content.profile;
const register = ({ content }) => content.register;
const policies = ({ content }) => content.policies;

const availableLanguages = ({ content }) => content.availableLanguages;
const language = ({ content }) => content.language;

const languageName = createSelector(language, (language) => language.language);
const languageCode = createSelector(language, (language) => language.code);

const signOnText = createSelector(register, (register) => ({
    emailOrPhone: register?.email_or_phone,
    passwordFor: register?.password_for,
    createAccount: register?.create_account,
    oneTimePinSent: register?.one_time_pin_sent,
    notYouGoBack: register?.not_you_go_back,
    wrongNumberGoBack: register?.wrong_number_go_back,
    password: register?.password,
    pin: register?.pin,
    verifyPassword: register?.verify_password,
    next: register?.next,
    submit: register?.sign_on,
}));

const mobileAppContent = createSelector(home, ({ mobile_app_info: info }) => ({
    title: info?.[0]?.title,
    screenshot: info?.[1]?.image?.filename,
    onGooglePlay: info?.[2]?.image?.filename,
    onAppStore: info?.[3]?.image?.filename,
}));

const cookiePolicy = createSelector(
    policies,
    ({ cookie_policy: cookiePolicy }) => cookiePolicy?.[0],
);
const privacyPolicy = createSelector(policies, ({ privacy_policy: privacyPolicy }) => ({
    title: startCase(toLower(privacyPolicy?.[0].title?.toLowerCase() ?? '')),
    policy: privacyPolicy,
}));

const contentLoaded = ({ content }) => content
    .contentLoaded
    .reduce((contentLoaded, storyLoaded) => contentLoaded && storyLoaded, true);

export {
    home,
    profile,
    register,
    policies,
    availableLanguages,
    language,
    languageName,
    languageCode,
    signOnText,
    mobileAppContent,
    cookiePolicy,
    privacyPolicy,
    contentLoaded,
};
