import { Provider } from 'react-redux';
import { combineReducers } from 'redux';
import { createEpicMiddleware, combineEpics } from 'redux-observable';

import configStore from 'libraries/configStore';
import { epics as systemEpics } from 'libraries/system';

import { reducer as ui, epics as uiEpics } from 'models/ui';
import { reducer as notifications, epics as notificationsEpics } from 'models/notifications';
import { reducer as user, epics as userEpics } from 'models/user';
import { reducer as content, epics as contentEpics } from 'models/content';

const ModelProvider = ({ options, children }) => {
    const rootReducer = combineReducers({
        ui,
        notifications,
        user,
        content,
    });

    const rootEpic = combineEpics(
        systemEpics,
        uiEpics,
        notificationsEpics,
        userEpics,
        contentEpics,
    );

    const epicMiddleware = createEpicMiddleware({
        dependencies: { options },
    });

    const middleWares = [epicMiddleware];
    const store = configStore(rootReducer, {}, middleWares);

    epicMiddleware.run(rootEpic);

    return <Provider store={store}>{ children }</Provider>;
};

export default ModelProvider;
