import { compose } from 'redux';

import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Box from '@material-ui/core/Box';
import Rating from '@material-ui/lab/Rating';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { withStyles, useTheme } from '@material-ui/core/styles';

import { UserCard } from '@grandmama/ui-user-card';
import { Switch } from '@grandmama/ui-switch';

import { withProps } from 'libraries/components';
import { withContent } from 'components';
import { user, userInfo, toggleActive } from 'models/user';
import { profile } from 'models/content';

import TextDisplay from '../textDisplay';
import styles from './styles';

const UserHeader = ({
    classes,
    user,
    userInfo,
    toggleActive,
    email,
    address,
    phone,
    available,
    notAvailable,
}) => {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        userInfo?.firstName
            ? (
                <UserCard
                    available={userInfo?.available}
                    name={`${userInfo?.firstName} ${userInfo?.lastName}`}
                    photo={userInfo?.photo}
                    monogram={`${userInfo?.firstName?.charAt(0)}${userInfo?.lastName?.charAt(0)}`}
                >
                    {mobile && userInfo?.rating && (
                        <div className={classes.rateRootMobile}>
                            <Rating name="read-only" value={userInfo?.rating} readOnly />
                            <Box ml={2}>{`${userInfo?.rating}/5`}</Box>
                        </div>
                    )}
                    <Grid
                        className={classes.grid}
                        container
                        spacing={5}
                        justify="space-between"
                        alignItems="stretch"
                    >
                        <Grid item xs={12} sm={12} md="auto">
                            <FormControlLabel
                                className={classes.rating}
                                labelPlacement="start"
                                control={(
                                    <Switch
                                        checked={userInfo?.available}
                                        onChange={toggleActive}
                                        disabled={!userInfo?.reviewed}
                                        name="available"
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    />
                                )}
                                label={userInfo?.available ? available : notAvailable}
                            />
                        </Grid>
                        { !mobile && (
                            <Grid item>
                                {userInfo?.rating && (
                                    <div className={classes.rateRoot}>
                                        <Rating name="read-only" value={userInfo?.rating} readOnly />
                                        <Box ml={2}>{`${userInfo?.rating}/5`}</Box>
                                    </div>
                                )}
                            </Grid>
                        )}
                    </Grid>
                    <div className={classes.spacer} />
                    <Grid
                        className={classes.grid}
                        container
                        spacing={5}
                        justify="space-between"
                        alignItems="stretch"
                    >
                        <Grid item>
                            <TextDisplay title={email} value={user?.email} />
                        </Grid>
                        <Grid item>
                            <TextDisplay title={address} value={userInfo?.address} />
                        </Grid>
                        <Grid item>
                            <TextDisplay title={phone} value={userInfo?.phoneNumber} />
                        </Grid>
                    </Grid>
                </UserCard>
            ) : null
    );
};
export default compose(
    withStyles(styles),
    withProps({ user, userInfo, toggleActive }),
    withContent('email', profile, 'email', 'Email'),
    withContent('address', profile, 'address', 'Address'),
    withContent('phone', profile, 'phone', 'Phone'),
    withContent('available', profile, 'available', 'Looking for opportunities'),
    withContent('notAvailable', profile, 'not_available', 'I have a job'),
)(UserHeader);
