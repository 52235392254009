import {
    from,
    Observable,
    of,
} from 'rxjs';
import {
    ignoreElements,
    map,
    filter,
    mergeMap,
    catchError,
    tap,
} from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import { identity } from 'lodash';
import axios from 'axios';
import {
    cfaSignOut,
    cfaSignInFacebook,
    cfaSignInApple,
    cfaSignInPhone,
    cfaSignInPhoneOnCodeSent,
} from 'capacitor-firebase-auth';
import { WITH_EMAIL } from '@grandmama/ui-login-form';

import {
    firebase,
    auth,
    db,
    facebookAuthProvider,
    appleAuthProvider,
    Document$,
} from 'services/firebaseDB';

import { deviceInfo } from 'models/ui';
import { sendNotification } from 'models/notifications';

import * as actions from './actions';

const checkForSignedInUser = () => from(
    new Observable((obs) => auth
        .onAuthStateChanged(
            (user) => obs.next(user),
            (err) => obs.error(err),
            () => obs.complete(),
        )),
).pipe(
    map((user) => (user ? actions.signIn.succeeded(user) : actions.signIn.failed())),
);

const userCheck = (actions$) => actions$.pipe(
    ofType(actions.checkUser.type),
    mergeMap(({ payload: emailOrPhone }) => axios.post(
        'https://cvpt9i9984.execute-api.eu-west-1.amazonaws.com/dev/user-check',
        { emailOrPhone },
        {
            headers: {
                'x-api-key': process.env.REACT_APP_USER_CHECK_API_KEY,
                'Content-Type': 'application/json',
            },
        },
    )),
    map(({ data }) => actions.checkUser.succeeded(data)),
    catchError((error) => from([
        sendNotification({
            message: error?.message,
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'error',
            },
        }),
        actions.checkUser.failed(),
    ])),
);

const signInEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.signIn.type),
    filter(() => state$.value.user.signOnForm.userCheckResults.userExists
        && state$.value.user.signOnForm.userCheckResults.logInType === WITH_EMAIL),
    mergeMap(({ payload: { email, password } }) => auth
        .signInWithEmailAndPassword(email, password)),
    map((response) => (response.user && actions.signIn.succeeded(response.user))),
    catchError((error) => of(sendNotification({
        message: error?.message,
        options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
        },
    }))),
);

const phoneSignOnSendCodeEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.checkUser.succeeded.type),
    filter(({ payload }) => payload.isPhone && state$.value.ui.deviceInfo.platform !== 'ios'),
    mergeMap(({ payload }) => auth
        .signInWithPhoneNumber(payload.emailOrPhone, window.recaptchaVerifier)),
    map((verifier) => actions.storeVerifier(verifier)),
    catchError((error) => from([
        sendNotification({
            message: error?.message,
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'error',
            },
        }),
        actions.singOnPreviousStep(),
    ])),
);

const phoneSignOnVerifyCodeEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.signIn.type),
    filter(() => state$.value.user.signOnForm.userCheckResults.isPhone
        && state$.value.ui.deviceInfo.platform !== 'ios'),
    mergeMap(({ payload }) => state$.value.user.phoneVerifier.confirm(payload.password)),
    map(({ user }) => actions.signIn.succeeded(user)),
    catchError((error) => from([
        sendNotification({
            message: error?.message,
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'error',
            },
        }),
        actions.singOnPreviousStep(),
    ])),
);

const phoneSignOnSendCodeIosEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.checkUser.succeeded.type),
    filter(({ payload }) => payload.isPhone
        && state$.value.ui.deviceInfo.platform === 'ios'),
    tap(({ payload }) => cfaSignInPhone(payload.emailOrPhone).subscribe()),
    mergeMap(() => cfaSignInPhoneOnCodeSent()),
    map((verifier) => actions.storeVerifier(verifier)),
    catchError((error) => from([
        sendNotification({
            message: error?.message,
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'error',
            },
        }),
        actions.singOnPreviousStep(),
    ])),
);

const phoneSignOnVerifyCodeIosEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.signIn.type),
    filter(() => state$.value.user.signOnForm.userCheckResults.isPhone
        && state$.value.ui.deviceInfo.platform === 'ios'),
    map(({ payload }) => firebase
        .auth
        .PhoneAuthProvider
        .credential(state$.value.user.phoneVerifier, payload.password)),
    mergeMap((credential) => auth.signInWithCredential(credential)),
    map(({ user }) => actions.signIn.succeeded(user)),
    catchError((error) => from([
        sendNotification({
            message: error?.message,
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'error',
            },
        }),
        actions.singOnPreviousStep(),
    ])),
);

const signUpEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.signIn.type),
    filter(() => !state$.value.user.signOnForm.userCheckResults.userExists
        && state$.value.user.signOnForm.userCheckResults.logInType === WITH_EMAIL),
    mergeMap(({
        payload: {
            email,
            password,
            verifyPassword,
            ...restUser
        },
    }) => auth
        .createUserWithEmailAndPassword(email, password)
        .then((response) => [response, { email, ...restUser }])),
    tap(([response, payload]) => db
        .collection('users')
        .doc(response?.user?.uid)
        .set({ ...payload, reviewed: false, createdAt: new Date() })),
    map(([response]) => (response.user && actions.signIn.succeeded(response.user))),
    catchError(({ message }) => of(sendNotification({
        message,
        options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
        },
    }))),
);

const facebookSignUpEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.signUpWithProvider.type),
    filter(({ payload }) => payload.provider === 'facebook'),
    mergeMap(() => (deviceInfo(state$.value).platform === 'web'
        ? auth.signInWithPopup(facebookAuthProvider)
        : cfaSignInFacebook())),
    map((resp) => ({
        user: resp?.user,
        uid: resp?.user?.uid,
        provider: resp?.additionalUserInfo?.providerId,
        email: resp?.additionalUserInfo?.profile?.email,
        firstName: resp?.additionalUserInfo?.profile?.first_name,
        lastName: resp?.additionalUserInfo?.profile?.last_name,
        photo: resp?.additionalUserInfo?.profile?.picture?.data?.url,
        isNewUser: resp?.additionalUserInfo?.isNewUser,
        reviewed: false,
    })),
    tap(({ user, isNewUser, ...userInfo }) => isNewUser && db
        .collection('users')
        .doc(userInfo?.uid)
        .set(userInfo)),
    map((response) => response.user || response),
    map((user) => (user && actions.signIn.succeeded(user))),
    catchError(({ message }) => of(sendNotification({
        message,
        options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
        },
    }))),
);

const appleSignUpEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.signUpWithProvider.type),
    filter(({ payload }) => payload.provider === 'apple'),
    mergeMap(() => (deviceInfo(state$.value).platform !== 'ios'
        ? auth.signInWithPopup(appleAuthProvider)
        : cfaSignInApple())),
    tap((info) => console.log(info)),
    map((resp) => ({
        user: resp?.user,
        uid: resp?.user?.uid,
        provider: resp?.additionalUserInfo?.providerId,
        email: resp?.additionalUserInfo?.profile?.email,
        firstName: resp?.additionalUserInfo?.profile?.first_name,
        lastName: resp?.additionalUserInfo?.profile?.last_name,
        photo: resp?.additionalUserInfo?.profile?.picture?.data?.url,
        isNewUser: resp?.additionalUserInfo?.isNewUser,
        reviewed: false,
    })),
    tap(({ user, isNewUser, ...userInfo }) => isNewUser && db
        .collection('users')
        .doc(userInfo?.uid)
        .set(userInfo)),
    map((response) => response.user || response),
    map((user) => (user && actions.signIn.succeeded(user))),
    catchError(({ message }) => of(sendNotification({
        message,
        options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
        },
    }))),
);

const requiredInfo = [
    'firstName',
    'lastName',
    'phoneNumber',
    'email',
    'address',
];

const loadUserInfoEpic = (actions$) => actions$.pipe(
    ofType(actions.signIn.succeeded.type, actions.addMissingInfo.succeeded.type),
    mergeMap(({ payload: { uid } }) => Document$('users', uid)),
    map((user) => [
        user,
        requiredInfo.reduce(
            (outcome, key) => (outcome || !user[key]),
            false,
        ),
    ]),
    mergeMap(([user, infoMissing]) => [
        actions.setUserInfo(user),
        actions.setUserInfoMissing(infoMissing),
    ]),
    catchError(() => from([
        actions.setUserInfo(true),
        actions.setUserInfoMissing(true),
    ])),
);

const addMissingInfoEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.addMissingInfo.type),
    map(({ payload }) => (requiredInfo.reduce(
        (reducedPayload, key) => ({ ...reducedPayload, [key]: payload[key] }),
        {},
    ))),
    mergeMap((payload) => db
        .collection('users')
        .doc(state$.value?.user?.user?.uid)
        .set(payload, { merge: true })),
    mergeMap(() => [
        sendNotification({
            message: 'Your information has been updated!',
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'success',
            },
        }),
        actions.addMissingInfo.succeeded({ uid: state$.value?.user?.user?.uid }),
    ]),
    catchError((error) => of(sendNotification({
        message: error?.message,
        options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
        },
    }))),
);

const logoutEpic = (actions$) => actions$.pipe(
    ofType(actions.signOut.type),
    mergeMap(() => auth
        .signOut()
        .then(identity)
        .catch(identity)),
    mergeMap(() => cfaSignOut()),
    ignoreElements(),
);

const resetPasswordEpic = (actions$) => actions$.pipe(
    ofType(actions.resetPassword.type),
    mergeMap(({ payload }) => auth.sendPasswordResetEmail(payload)),
    map(() => sendNotification({
        message: 'A reset email has been sent! Please check your email.',
        options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
        },
    })),
    catchError((error) => of(sendNotification({
        message: error?.message,
        options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
        },
    }))),
);

const toggleActiveEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.toggleActive.type),
    mergeMap(() => db
        .collection('users')
        .doc(state$.value?.user?.user?.uid)
        .update({ available: !state$.value?.user?.userInfo?.available })),
    mergeMap(() => [
        sendNotification({
            message: 'Your activity status has changed!',
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'info',
            },
        }),
        actions.toggleActive.succeeded(),
    ]),
    catchError((error) => of(sendNotification({
        message: error?.message,
        options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
        },
    }))),
);

const epics = combineEpics(
    checkForSignedInUser,
    userCheck,
    signInEpic,
    phoneSignOnSendCodeEpic,
    phoneSignOnVerifyCodeEpic,
    phoneSignOnSendCodeIosEpic,
    phoneSignOnVerifyCodeIosEpic,
    signUpEpic,
    facebookSignUpEpic,
    appleSignUpEpic,
    loadUserInfoEpic,
    logoutEpic,
    resetPasswordEpic,
    toggleActiveEpic,
    addMissingInfoEpic,
);

export default epics;
