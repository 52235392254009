import { useEffect } from 'react';
import {
    useLocation,
    // useHistory,
} from 'react-router-dom';
import { identity } from 'lodash';

import { useProps } from 'libraries/components';
import { languageCode, availableLanguages, setLanguage } from 'models/content';

const stateProps = {
    languageCode,
    availableLanguages,
    setLanguage,
};

const withLocale = (Component) => (props) => {
    const {
        languageCode,
        availableLanguages,
        setLanguage,
    } = useProps(stateProps);
    const location = useLocation();
    // const history = useHistory();

    useEffect(() => {
        if (availableLanguages.length > 0) {
            const pathLocale = location.pathname.split('/').filter(identity)?.[0];
            const locale = availableLanguages?.find((lang) => lang.code === pathLocale);
            // console.log('on load location', locale, languageCode);

            if (locale && locale?.code !== languageCode) {
                setLanguage(locale);
            }
        }
    }, [availableLanguages]);

    useEffect(() => {
        // const pathLocale = location.pathname.split('/').filter(identity)?.[0];
        // const locale = availableLanguages?.find((lang) => lang.code === pathLocale);
        // console.log('location', locale, languageCode);

        // if (!(locale?.code === languageCode)) {
        //     const route = location.pathname;
        //     // console.log('match', route, route.match(/^\/([\w]{2})\//g));
        //     const newRoute = route.match(/^\/([\w]{2})\//g)
        //         ? route.replace(locale?.code, languageCode)
        //         : `/${languageCode}${route}`;
        //
        //     // console.log('==-=-=-', route, newRoute);
        //     history.push(newRoute);
        // }
    }, [languageCode, availableLanguages]);

    return <Component {...props} />;
};

export default withLocale;
