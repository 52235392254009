import { compose } from 'redux';

import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import { withProps } from 'libraries/components';
import { withContent } from 'components';
import { userInfo, userInfoMissing } from 'models/user';
import { profile } from 'models/content';

import styles from './styles';

const PendingReview = ({
    classes,
    userInfo,
    userInfoMissing,
    title,
    note,
}) => (
    userInfoMissing || userInfo?.reviewed
        ? null
        : (
            <div className={classes.underReview}>
                <Typography variant="h4">
                    { title }
                </Typography>
                <Typography variant="h5">
                    { note }
                </Typography>
            </div>
        )
);

export default compose(
    withStyles(styles),
    withProps({ userInfo, userInfoMissing }),
    withContent('title', profile, 'not_reviewed_title', 'We will contact you shortly.'),
    withContent('note', profile, 'not_reviewed_note', 'If any of the details above are not valid, please contact us.'),
)(PendingReview);
