import { useState, useEffect } from 'react';
import { compose } from 'redux';
import { useLocation, withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ClearIcon from '@material-ui/icons/Clear';
import { withTheme, withStyles } from '@material-ui/core/styles';

import { withProps } from 'libraries/components';
import { privacyPolicy, cookiePolicy } from 'models/content';
import { deviceInfo } from 'models/ui';

import { Policy } from './components';
import styles from './styles';

const PolicyContainer = ({
    classes,
    theme,
    history,
    privacyPolicy,
    cookiePolicy,
    deviceInfo,
}) => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(isEmpty(privacyPolicy) || isEmpty(cookiePolicy));
    const [{ title, policy }, setPolicy] = useState({
        title: '',
        policy: '',
    });
    const { pathname, search } = useLocation();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        if (search.includes('cookiePolicyOpen')) {
            setPolicy(cookiePolicy);
            setOpen(true);
        }
        if (search.includes('privacyPolicyOpen')) {
            setPolicy(privacyPolicy);
            setOpen(true);
        }
    }, [search]);

    useEffect(() => {
        if (!isEmpty(privacyPolicy) && !isEmpty(cookiePolicy)) {
            setLoading(false);
        }
    }, [privacyPolicy, cookiePolicy]);

    const handleClose = () => {
        setOpen(false);
        history.push(pathname);
    };

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            scroll="paper"
        >
            <DialogTitle
                className={`${classes.title} ${deviceInfo.platform !== 'web' ? classes.mobilePadding : ''}`}
                disableTypography
            >
                <Typography variant="h4">
                    { title ?? '' }
                </Typography>
                <div className={classes.spacer} />
                <IconButton onClick={handleClose}>
                    <ClearIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                { !loading && <Policy policy={policy} /> }
            </DialogContent>
        </Dialog>
    );
};

export default compose(
    withRouter,
    withStyles(styles),
    withTheme,
    withProps({
        privacyPolicy,
        cookiePolicy,
        deviceInfo,
    }),
)(PolicyContainer);
