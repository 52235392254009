import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

const TextDisplay = ({ classes, title, value }) => (
    title && value ? (
        <div className={classes.root}>
            <Typography variant="overline">{ title }</Typography>
            <Typography>{ value }</Typography>
        </div>
    ) : null
);
export default withStyles(styles)(TextDisplay);
