import { compose } from 'redux';
import { SplashScreen } from '@grandmama/ui-splash-screen';

import {
    Switch,
    Route,
} from 'react-router-dom';
import {
    Home,
    SignOn,
    MyProfile,
    RecoverPassword,
    AppDownload,
} from 'routes';

import {
    useNotifier,
    PrivateRoute,
    Layout,
    RouterMonitor,
} from 'components';

import { withProps } from 'libraries/components';
import { user, signInChecked } from 'models/user';
import { languageCode, contentLoaded } from 'models/content';

const base = '/:locale(en|el)?';

const App = ({
    user,
    signInChecked,
    languageCode,
    contentLoaded,
}) => {
    useNotifier();

    return ((!user && !signInChecked) || !contentLoaded
        ? <SplashScreen logo="https://i.ibb.co/hDphXbj/nRKFsQgA.png" out={signInChecked} />
        : (
            <RouterMonitor>
                <Switch>
                    <Route path={`${base}/sign-on`} component={SignOn} />
                    <Route path={`${base}/recover-password`} component={RecoverPassword} />
                    <Layout>
                        <PrivateRoute path={`${base}/my-profile`} redirectTo={`/${languageCode}/sign-on`} exact isAuthenticated={user}>
                            <MyProfile />
                        </PrivateRoute>
                        <Route path={`${base}/download`} component={AppDownload} />
                        <Route path={`${base}/`} exact>
                            <Home />
                        </Route>
                        {/* <Route path="*" exact components={NotFound} /> */}
                    </Layout>
                </Switch>
            </RouterMonitor>
        )
    );
};

export default compose(
    withProps({
        user,
        signInChecked,
        languageCode,
        contentLoaded,
    }),
)(App);
