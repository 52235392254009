const styles = (theme) => ({
    graphic: {
        width: '50%',
        margin: '0 auto',
        display: 'block',
    },
    paper: {
        padding: theme.spacing(4),
        width: '60%',
        margin: '0 auto',
    },
    title: {
        textAlign: 'center',
        margin: '2em 0 3em 0',
    },
    spacer: {
        height: '8em',
    },
    step: {
        marginBottom: '8em',
    },
});

export default styles;
