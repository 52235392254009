const styles = (theme) => ({
    spacer: {
        margin: theme.spacing(4),
    },
    infoContainer: {
        padding: theme.spacing(4),
    },
});

export default styles;
