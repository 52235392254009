import { compose } from 'redux';
import { AppBar } from '@grandmama/ui-app-bar';
import { LanguagePicker } from '@grandmama/ui-language-picker';
import { withStyles } from '@material-ui/core/styles';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import { withUserOptions } from 'utils';
import { withProps } from 'libraries/components';
import { availableLanguages, language, setLanguage } from 'models/content';
import withCookieNotice from '../withCookieNotice';

import { Footer } from './components';

import styles from './styles';
import './styles.css';

console.log(typeof withCookieNotice, typeof withCookieNotice?.());

const Layout = ({
    classes,
    children,
    userOptions,
    availableLanguages,
    language,
    setLanguage,
    window,
}) => {
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 80,
        target: window ? window() : undefined,
    });

    return (
        <AppBar
            logo="https://i.ibb.co/8KbmkKd/grandmama-white.png"
            options={userOptions}
            barProps={{
                className: `${classes.appBar} mobile-padding`,
                elevation: trigger ? 4 : 0,
            }}
            toolBarChildren={(
                <LanguagePicker
                    currentLanguage={language}
                    availableLanguages={availableLanguages}
                    buttonProps={{
                        className: classes.languagePicker,
                    }}
                    selectLanguage={setLanguage}
                />
            )}
        >
            <div className={classes.root}>
                {children}
                <div className={classes.footerSpacer} />
                <Footer />
            </div>
        </AppBar>
    );
};

export default compose(
    withStyles(styles),
    withProps({
        availableLanguages,
        language,
        setLanguage,
    }),
    withCookieNotice,
    withUserOptions,
)(Layout);
