import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { useProps } from 'libraries/components';

import { user, signOut } from 'models/user';
import { languageCode } from 'models/content';

const stateProps = {
    user,
    signOut,
    languageCode,
};

const withUserOptions = (Component) => (props) => {
    const history = useHistory();
    const { user, signOut, languageCode } = useProps(stateProps);

    const singedIn = [
        { title: 'Sign Out', onClick: signOut, variant: 'outlined' },
    ];

    const singedOut = [
        {
            title: 'Sign On',
            onClick: useCallback(() => history.push(`/${languageCode}/sign-on`), [history]),
        },
    ];

    return <Component userOptions={user ? singedIn : singedOut} {...props} />;
};

export default withUserOptions;
