import { useProps } from 'libraries/components';
import { Redirect } from 'react-router-dom';

import { deviceInfo } from 'models/ui';
import { languageCode } from 'models/content';

const stateSelectors = {
    deviceInfo,
    languageCode,
};

const withMobileRedirect = (to) => (Component) => (props) => {
    const { deviceInfo, languageCode } = useProps(stateSelectors);

    return deviceInfo?.platform === 'web'
        ? <Component {...props} />
        : <Redirect to={`/${languageCode}${to}`} />;
};

export default withMobileRedirect;
