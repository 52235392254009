const styles = () => ({
    root: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        flexFlow: 'row wrap',
        alignItems: 'center',
        justifyContent: 'center',
    },
    container: {
        textAlign: 'center',
    },
    title: {
        marginBottom: '1em',
    },
});

export default styles;
