import { combineEpics, ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import {
    filter,
    map,
    tap,
    mergeMap,
} from 'rxjs/operators';

import { Plugins } from '@capacitor/core';
import cookies from 'browser-cookies';
import { closeNotification } from 'models/notifications';
import { setDeviceInfo, acceptCookies } from './actions';

const { Device } = Plugins;
const ACCEPTED_COOKIES = 'acceptedCookies';

const getDeviceInfoEpic = () => from(Device.getInfo()).pipe(
    map(setDeviceInfo),
);

const loadAcceptedCookies = () => of(cookies.get(ACCEPTED_COOKIES)).pipe(
    map((accepted) => (accepted ? acceptCookies.succeeded() : acceptCookies.failed())),
);

const acceptCookiesEpic = (actions$, state$) => actions$.pipe(
    ofType(acceptCookies.type),
    filter(() => state$.value.ui.deviceInfo.platform === 'web'),
    tap(() => cookies.set(ACCEPTED_COOKIES, 'true')),
    tap(({ payload }) => console.log(payload)),
    mergeMap(({ payload }) => [acceptCookies.succeeded(), closeNotification(payload)]),
);

export default combineEpics(
    getDeviceInfoEpic,
    loadAcceptedCookies,
    acceptCookiesEpic,
);
