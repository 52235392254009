import { ThemeProvider } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter as Router } from 'react-router-dom';
import ModelProvider from 'modelProvider';

const Providers = ({
    options,
    theme,
    maxNotifications,
    children,
}) => (
    <ModelProvider options={options}>
        <ThemeProvider theme={theme}>
            <Router>
                <SnackbarProvider maxSnack={maxNotifications}>
                    { children }
                </SnackbarProvider>
            </Router>
        </ThemeProvider>
    </ModelProvider>
);

export default Providers;
