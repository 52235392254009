const styles = (theme) => ({
    root: {
        textAlign: 'center',
        marginTop: theme.spacing(6),
    },
    title: {
        marginBottom: theme.spacing(2),
    },
    image: {
        width: '30%',
    },
});

export default styles;
