import { createReducer } from '@reduxjs/toolkit';

import * as actions from './actions';

const initState = {
    missingInfoDialogOpen: false,
    deviceInfo: {},
    acceptedCookies: false,
};

const reducer = createReducer(initState, (builder) => builder
    .addCase(actions.setDeviceInfo, (state, { payload }) => {
        state.deviceInfo = payload;
    })
    .addCase(actions.acceptCookies.succeeded, (state) => {
        state.acceptedCookies = true;
    })
    .addCase(actions.acceptCookies.failed, (state) => {
        state.acceptedCookies = false;
    })
    .addCase(actions.openMissingInfoDialog, (state) => {
        state.missingInfoDialogOpen = true;
    })
    .addCase(actions.closeMissingInfoDialog, (state) => {
        state.missingInfoDialogOpen = false;
    }));

export default reducer;
