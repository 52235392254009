import { createReducer } from '@reduxjs/toolkit';

import * as actions from './actions';

const en = {
    language: 'English',
    code: 'en',
};

const initState = {
    availableLanguages: [en],
    language: en,
    home: {},
    profile: {},
    register: {},
    policies: {},
    contentLoaded: [false, false, false],
};

const reducer = createReducer(initState, (builder) => builder
    .addCase(actions.loadHomeContent.succeeded, (state, { payload }) => {
        state.home = payload;
        state.contentLoaded[0] = true;
    })
    .addCase(actions.loadRegisterContent.succeeded, (state, { payload }) => {
        state.register = payload;
        state.contentLoaded[1] = true;
    })
    .addCase(actions.loadPoliciesContent.succeeded, (state, { payload }) => {
        state.policies = payload;
        state.contentLoaded[2] = true;
    })
    .addCase(actions.setAvailableLanguages, (state, { payload }) => {
        state.availableLanguages = payload;
    })
    .addCase(actions.setLanguage, (state, { payload }) => {
        state.language = payload;
    })
    .addCase(actions.setProfileContent, (state, { payload }) => {
        state.profile = payload;
    }));

export default reducer;
