import { combineEpics, ofType } from 'redux-observable';
import { of } from 'rxjs';
import {
    mergeMap,
    // tap,
    map,
    // ignoreElements,
} from 'rxjs/operators';

import Story$ from 'services/storyblok';
import '@formatjs/intl-displaynames/polyfill';
import '@formatjs/intl-displaynames/locale-data/en';

import { signIn } from 'models/user';
import * as actions from './actions';

const languageNames = new Intl.DisplayNames(['en'], { type: 'language' });

const loadLanguages = () => of([]).pipe(
    mergeMap(() => Story$('cdn/spaces/caregiver/')),
    // eslint-disable-next-line camelcase
    map(({ space: { language_codes } }) => language_codes
        .map((code) => ({
            language: languageNames.of(code),
            code,
        }))),
    map(actions.setAvailableLanguages),
);

const loadContent = () => of([]).pipe(
    mergeMap(() => [
        actions.loadHomeContent(),
        actions.loadRegisterContent(),
        actions.loadPoliciesContent(),
    ]),
);

const loadHomeContent = (actions$, state$) => actions$.pipe(
    ofType(actions.loadHomeContent.type, actions.setLanguage.type),
    mergeMap(() => Story$(`cdn/stories/${state$.value.content.language.code}/home/`)),
    map(({ story: { content } }) => actions.loadHomeContent.succeeded(content)),
);

const loadRegisterContent = (actions$, state$) => actions$.pipe(
    ofType(actions.loadRegisterContent.type, actions.setLanguage.type),
    mergeMap(() => Story$(`cdn/stories/${state$.value.content.language.code}/register/`)),
    map(({ story: { content } }) => actions.loadRegisterContent.succeeded(content)),
);

const loadPoliciesContent = (actions$, state$) => actions$.pipe(
    ofType(actions.loadPoliciesContent.type, actions.setLanguage.type),
    mergeMap(() => Story$(`cdn/stories/${state$.value.content.language.code}/policies/`)),
    map(({ story: { content } }) => actions.loadPoliciesContent.succeeded(content)),
);

const loadProfileContent = (actions$, state$) => actions$.pipe(
    ofType(signIn.succeeded.type, actions.setLanguage.type),
    mergeMap(() => Story$(`cdn/stories/${state$.value.content.language.code}/my-profile/`)),
    map(({ story: { content } }) => actions.setProfileContent(content)),
);

export default combineEpics(
    loadLanguages,
    loadContent,
    loadHomeContent,
    loadRegisterContent,
    loadPoliciesContent,
    loadProfileContent,
);
