import { compose } from 'redux';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import { notFound } from 'assets';
import { withProps } from 'libraries/components';
import { userInfo } from 'models/user';

import styles from './styles';

const NotFound = ({ classes, userInfo }) => (
    <>
        { console.log(classes) }
        <img className={classes.graphic} src={notFound} alt="Not Found" />
        <Typography className={classes.title} variant="h4">
            There&apos;s nothing here...
        </Typography>
        <Typography className={classes.title} variant="h5">
            <Link to={userInfo?.firstName ? '/my-profile' : '/'}>Go Back</Link>
        </Typography>
    </>
);

export default compose(
    withStyles(styles),
    withProps({ userInfo }),
)(NotFound);
