const styles = (theme) => ({
    spacer: {
        margin: theme.spacing(4),
    },
    infoContainer: {
        padding: theme.spacing(4),
    },
    rateRoot: {
        width: 200,
        display: 'flex',
        alignItems: 'center',
    },
    grid: {
        maxWidth: '100%',
        margin: 0,
    },
    rating: {
        marginLeft: 1,
    },
    rateRootMobile: {
        margin: '0 auto',
        width: 'fit-content',
        display: 'flex',
        alignItems: 'center',
    },
    spinner: {
        position: 'fixed',
        top: '50vh',
        right: '50vw',
    },
    underReview: {
        textAlign: 'center',
    },
});

export default styles;
