import { useEffect } from 'react';
import { useSnackbar } from 'notistack';

import useProps from 'libraries/components/useProps';
import { removeNotification as removeN, notifications as not } from 'models/notifications';

let displayed = [];

const useNotifier = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const props = useProps({ removeN, not });

    const storeDisplayed = (id) => {
        displayed = [...displayed, id];
    };

    const removeDisplayed = (id) => {
        displayed = [...displayed.filter((key) => id !== key)];
    };

    useEffect(() => {
        props.not && props.not.forEach(({
            key, message, options = {}, dismissed = false,
        }) => {
            if (dismissed) {
                closeSnackbar(key);
                return;
            }

            if (displayed.includes(key)) return;

            enqueueSnackbar(message, {
                key,
                ...options,
                onClose: (event, reason, myKey) => {
                    if (options.onClose) {
                        options.onClose(event, reason, myKey);
                    }
                },
                onExited: (event, myKey) => {
                    props.removeN(myKey);
                    removeDisplayed(myKey);
                },
            });

            storeDisplayed(key);
        });
    }, [closeSnackbar, enqueueSnackbar, props]);

    return null;
};

export default useNotifier;
