const styles = () => ({
    title: {
        display: 'flex',
    },
    spacer: {
        flexGrow: 1,
    },
    mobilePadding: {
        paddingTop: '2em',
    },
});

export default styles;
