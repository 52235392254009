const emailRgx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const withFormValidation = (Component) => (props) => {
    const validate = (values) => {
        const errors = {};

        if (!values.firstName) {
            errors.firstName = 'Please fill in your first name.';
        }
        if (!values.lastName) {
            errors.lastName = 'Please fill in your last name.';
        }
        if (!values.phoneNumber) {
            errors.phoneNumber = 'Please fill in your mobile phone number.';
        }
        if (values?.phoneNumber?.length < 11) {
            errors.phoneNumber = 'Not enough digits, please add your country code as well. (i.e. For Greece add +30 or  0030)';
        }
        if (values.email && !values?.email?.match(emailRgx)) {
            errors.email = 'Email address badly formatted';
        }
        if (!values.address) {
            errors.address = 'Please fill in your home address.';
        }

        return errors;
    };

    return <Component validate={validate} {...props} />;
};

export default withFormValidation;
