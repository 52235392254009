import { compose } from 'redux';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import { withContent } from 'components';
import { home } from 'models/content';

import styles from './styles';

const BannerContext = ({ classes, title, button }) => (
    <div className={classes.root}>
        <div className={classes.container}>
            <Typography
                className={classes.title}
                variant="h2"
            >
                { title }
            </Typography>
            <Button
                variant="contained"
                color="primary"
                size="large"
                component={Link}
                to="/sign-on"
            >
                { button }
            </Button>
        </div>
    </div>
);

export default compose(
    withStyles(styles),
    withContent('title', home, 'join_title', 'Become a Caregiver today!'),
    withContent('button', home, 'join_button', 'Join Now'),
)(BannerContext);
