import { compose } from 'redux';
import { Link } from 'react-router-dom';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

import { withProps } from 'libraries/components';
import { privacyPolicy, cookiePolicy } from 'models/content';

import { PolicyContainer } from './components';
import styles from './styles';

const Footer = ({ classes, privacyPolicy, cookiePolicy }) => (
    <div className={classes.root}>
        <Toolbar className={classes.toolbar}>
            <PolicyContainer />
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
            >
                <Grid item>
                    <img
                        className={classes.logo}
                        src="https://i.ibb.co/BtpkD7S/grandmama-White.png"
                        alt="logo"
                    />
                    <Typography className={classes.text}>
                        {`Copyright © ${new Date().getFullYear()} Grandmama, LTD.`}
                    </Typography>
                </Grid>
                <Grid className={classes.policyContainer} item md={12}>
                    <Link
                        className={classes.link}
                        to={(location) => ({ ...location, search: '?privacyPolicyOpen=true' })}
                    >
                        <Typography className={classes.policyLink} display="inline">
                            { privacyPolicy?.title }
                        </Typography>
                    </Link>
                    <Link
                        className={classes.link}
                        to={(location) => ({ ...location, search: '?cookiePolicyOpen=true' })}
                    >
                        <Typography className={classes.policyLink} display="inline">
                            { cookiePolicy?.title }
                        </Typography>
                    </Link>
                </Grid>
            </Grid>
        </Toolbar>
    </div>
);

export default compose(
    withStyles(styles),
    withProps({ privacyPolicy, cookiePolicy }),
)(Footer);
