const styles = (theme) => ({
    appBar: {
        backgroundColor: `${theme.palette.primary.main} !important`,
        // paddingTop: 60,
    },
    root: {
        position: 'relative',
        minHeight: '100vh',
    },
    footerSpacer: {
        height: '12em',
    },
    languagePicker: {
        color: theme.palette.primary.contrastText,
        marginRight: theme.spacing(2),
    },
});

export default styles;
