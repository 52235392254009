const styles = () => ({
    graphic: {
        width: '50%',
        display: 'block',
        margin: '2em auto',
    },
    title: {
        textAlign: 'center',
    },
});

export default styles;
