import { compose } from 'redux';
import { Parallax, withController } from 'react-scroll-parallax';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import { withStyles, withTheme } from '@material-ui/core/styles';

import { withProps } from 'libraries/components';
import { mobileAppContent } from 'models/content';
import styles from './styles';

const MobileApp = ({
    classes,
    mobileAppContent,
    parallaxController,
    theme,
}) => {
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <img
                    className={classes.screenshot}
                    src={mobileAppContent.screenshot}
                    alt="screenshot"
                    onLoad={parallaxController.update}
                />
                <div className={classes.whiteBack} />
                <div className={classes.blackBack} />
                <div className={classes.textContainer}>
                    <Parallax x={[-60, 20]} disabled={isMobile}>
                        <Typography variant="h2" className={classes.text}>
                            {mobileAppContent.title}
                        </Typography>
                    </Parallax>
                    <div className={classes.badgeContainer}>
                        <img
                            className={classes.appleBadge}
                            src={mobileAppContent.onAppStore}
                            alt="available on appstore"
                        />
                        <img
                            className={classes.googleBadge}
                            src={mobileAppContent.onGooglePlay}
                            alt="available on google play"
                        />
                    </div>
                </div>
                <div className={classes.polygon} />
            </div>
        </div>
    );
};

export default compose(
    withStyles(styles),
    withTheme,
    withProps({ mobileAppContent }),
    withController,
)(MobileApp);
