const styles = (theme) => ({
    root: {
        overflow: 'hidden',
    },
    container: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        height: '60vh',
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        justifyContent: 'center',
        borderBottomLeftRadius: '50% 30%',
        borderBottomRightRadius: '50% 30%',
        width: '105%',
        left: '-3%',
        position: 'relative',
    },
    logo: {
        margin: '0 auto',
        width: '10em',
    },
    title: {
        marginBottom: theme.spacing(4),
    },
    slogan: {
        padding: theme.spacing(2),
    },
});

export default styles;
