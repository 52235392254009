import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { GraphicBox } from '@grandmama/ui-graphic-box';
import { Parallax } from 'react-scroll-parallax';

import styles from './styles';

const Step = ({
    classes,
    className,
    title,
    body,
    image,
    rtl,
    ...props
}) => (
    <GraphicBox
        rtl={rtl}
        graphic={(
            <Parallax y={[-30, 30]}>
                <img className={classes.graphic} src={image} alt="graphic" />
            </Parallax>
        )}
        paperProps={{
            className: `${classes.paper} ${className}`,
        }}
        gridProps={{
            alignItems: 'center',
        }}
        {...props}
    >
        <Typography variant="h4">
            {title}
        </Typography>
        <br />
        <br />
        <Typography>
            {body}
        </Typography>
    </GraphicBox>
);

export default withStyles(styles)(Step);
