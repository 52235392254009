import { actionCreator, effectActionCreator } from 'libraries/system';

const signIn = effectActionCreator('SIGN_IN');
const signInWithProvider = effectActionCreator('SIGN_IN_WITH_PROVIDER');
const signUp = effectActionCreator('SIGN_UP');
const signUpWithProvider = effectActionCreator('SIGN_UP_WITH_PROVIDER');
const signOut = effectActionCreator('SIGN_OUT');
const resetPassword = effectActionCreator('RESET_PASSWORD');
const toggleActive = effectActionCreator('TOGGLE_ACTIVE');
const addMissingInfo = effectActionCreator('ADD_MISSING_INFO');
const checkUser = effectActionCreator('CHECK_USER');

const setUserInfo = actionCreator('SET_USER_INFO');
const setUserInfoMissing = actionCreator('SET_USER_INFO_MISSING');
const singOnPreviousStep = actionCreator('SIGN_ON_PREVIOUS_STEP');
const recaptchaSolved = actionCreator('RECAPTCHA_SOLVED');
const storeVerifier = actionCreator('STORE_VERIFIER');

export {
    signIn,
    signInWithProvider,
    signUp,
    signUpWithProvider,
    signOut,
    resetPassword,
    toggleActive,
    addMissingInfo,
    checkUser,
    setUserInfo,
    setUserInfoMissing,
    singOnPreviousStep,
    recaptchaSolved,
    storeVerifier,
};
