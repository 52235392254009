import { TextField } from 'mui-rff';

const InfoForm = ({ onSubmit, ...props }) => (
    <form onSubmit={onSubmit} {...props}>
        <TextField
            name="firstName"
            label="First Name"
            required
        />
        <TextField
            name="lastName"
            label="Last Name"
            required
        />
        <TextField
            name="email"
            label="Email"
        />
        <TextField
            name="phoneNumber"
            label="Mobile Phone"
            required
        />
        <TextField
            name="address"
            label="Dashboard Address"
            required
        />
    </form>
);

export default InfoForm;
