import { Redirect } from 'react-router-dom';

import { useProps } from 'libraries/components';
import { user } from 'models/user';
import { languageCode } from 'models/content';

const modelProps = {
    user,
    languageCode,
};

const withRedirect = (redirectTo) => (Component) => (props) => {
    const { user, languageCode } = useProps(modelProps);

    return user ? <Redirect push to={`/${languageCode}${redirectTo}`} /> : <Component {...props} />;
};

export default withRedirect;
