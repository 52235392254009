const deviceInfo = ({ ui }) => ui.deviceInfo;
const notifications = ({ ui }) => ui.notifications;
const missingInfoDialogOpen = ({ ui }) => ui.missingInfoDialogOpen;
const acceptedCookies = ({ ui }) => ui.acceptedCookies;

export {
    deviceInfo,
    notifications,
    missingInfoDialogOpen,
    acceptedCookies,
};
