import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import Button from '@material-ui/core/Button';

import { useProps } from 'libraries/components';

import { sendNotification } from 'models/notifications';
import { acceptedCookies, acceptCookies, deviceInfo } from 'models/ui';
import { contentLoaded, cookiePolicy } from 'models/content';

import useStyles from './styles';

const propCreators = {
    sendNotification,
    acceptedCookies,
    acceptCookies,
    contentLoaded,
    cookiePolicy,
    deviceInfo,
};

const withCookieNotice = (Component) => (props) => {
    const classes = useStyles();
    const {
        sendNotification,
        acceptedCookies,
        acceptCookies,
        contentLoaded,
        cookiePolicy,
        deviceInfo,
    } = useProps(propCreators);

    useEffect(() => {
        if (contentLoaded && !acceptedCookies && deviceInfo.platform === 'web') {
            sendNotification({
                message: cookiePolicy?.banner,
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'info',
                    persist: true,
                    action: (key) => (
                        <>
                            <Button
                                className={classes.button}
                                component={Link}
                                to={(location) => ({ ...location, search: '?cookiePolicyOpen=true' })}
                            >
                                { cookiePolicy?.title }
                            </Button>
                            <Button className={classes.button} onClick={() => acceptCookies(key)}>
                                { cookiePolicy?.action }
                            </Button>
                        </>
                    ),
                },
            });
        }
    }, [contentLoaded]);

    return <Component {...props} />;
};

export default withCookieNotice;
