const styles = (theme) => ({
    graphic: {
        width: '50%',
        margin: 'auto',
        display: 'block',
        verticalAlign: 'middle',
    },
    paper: {
        padding: theme.spacing(4),
        width: '60%',
        margin: '0 auto',
    },
    [theme.breakpoints.down('sm')]: {
        paper: {
            minWidth: '100%',
            margin: '0',
        },
        graphic: {
            marginTop: '5em',
        },
    },
    title: {
        textAlign: 'center',
        margin: '3em 0',
    },
});

export default styles;
