import { ParallaxBanner } from 'react-scroll-parallax';

import { hero } from 'assets';

import { BannerContext } from './components';

const JoinBanner = () => (
    <ParallaxBanner
        layers={[
            {
                image: hero,
                amount: 0.5,
            },
        ]}
        style={{
            height: '60vh',
        }}
    >
        <BannerContext />
    </ParallaxBanner>
);

export default JoinBanner;
