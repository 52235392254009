import { compose } from 'redux';

import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';

import { FacebookButton } from '@grandmama/ui-facebook-button';
import { AppleButton } from '@grandmama/ui-apple-button';
import { LoginForm } from '@grandmama/ui-login-form';

import { withContent, Recaptcha } from 'components';
import { withProps } from 'libraries/components';
import {
    signIn,
    signUpWithProvider,
    checkUser,
    signOnForm,
    singOnPreviousStep,
    recaptchaSolvedResult,
    resetPassword,
} from 'models/user';
import {
    languageCode,
    register,
    signOnText,
} from 'models/content';

import { withRedirect } from 'utils';

import styles from './styles';
import './styles.css';

const SignOn = ({
    classes,
    signIn,
    signUpWithProvider,
    staticText,
    signOnText,
    checkUser,
    singOnPreviousStep,
    signOnForm,
    recaptchaSolvedResult,
    resetPassword,
}) => (
    <div className="mobile-padding">
        <LoginForm
            bigLogo="https://i.ibb.co/yNSS8tF/ex3Kt-NQ.png"
            logo="https://i.ibb.co/hDphXbj/nRKFsQgA.png"
            submitText={staticText?.sign_on ?? 'Sign On'}
            onSubmit={(result) => signIn(result)}
            step={signOnForm.step}
            prevStep={singOnPreviousStep}
            userCheck={checkUser}
            loading={signOnForm.loading}
            userCheckResults={signOnForm.userCheckResults}
            staticText={signOnText}
            blocked={!recaptchaSolvedResult}
            recaptcha={<Recaptcha />}
        >
            <div className={classes.spacer} />
            {signOnForm.userCheckResults.isEmail && signOnForm.userCheckResults.userExists && (
                <Button onClick={() => resetPassword(signOnForm.userCheckResults.emailOrPhone)}>
                    {staticText?.forgot_password || 'Forgot password?'}
                </Button>
            )}
            <div className={classes.spacer} />
            <Divider className={classes.divider} />
            <div className={classes.spacer} />
            <FacebookButton
                fullWidth
                label={staticText?.facebook ?? 'Sign On with Facebook'}
                onClick={() => signUpWithProvider({ provider: 'facebook' })}
            />
            <div className={classes.spacer} />
            <AppleButton
                fullWidth
                label={staticText?.apple ?? 'Continue with Apple'}
                onClick={() => signUpWithProvider({ provider: 'apple' })}
            />
        </LoginForm>
    </div>
);

export default compose(
    withStyles(styles),
    withProps({
        signIn,
        signUpWithProvider,
        languageCode,
        signOnText,
        checkUser,
        singOnPreviousStep,
        signOnForm,
        recaptchaSolvedResult,
        resetPassword,
    }),
    withRedirect('/my-profile'),
    withContent('staticText', register),
)(SignOn);
