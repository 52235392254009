import { createTheme } from '@material-ui/core/styles';

const lightTheme = createTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#7C506B',
        },
        secondary: {
            main: '#D14F46',
        },
    },
});

export default lightTheme;
