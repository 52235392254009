const styles = () => ({
    container: {
        marginTop: -60,
    },
    spacer: {
        height: '8em',
    },
});

export default styles;
