import { compose } from 'redux';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import CountUp from 'react-countup';

import { home } from 'models/content';
import { withContent } from 'components';

import styles from './styles';

const Header = ({ classes, sloganPtOne, sloganPtTwo }) => (
    <div className={classes.root}>
        <div className={classes.container}>
            <img
                className={classes.logo}
                src="https://i.ibb.co/wMbTgSY/open-hands.png"
                alt="caregiver"
            />
            <Typography className={classes.title} variant="h2">
                Caregiver
            </Typography>
            <Typography className={classes.slogan} variant="h5">
                {sloganPtOne}
                <strong>
                    <CountUp end={3247} duration={4} />
                </strong>
                {sloganPtTwo}
            </Typography>
        </div>
    </div>
);

export default compose(
    withStyles(styles),
    withContent('sloganPtOne', home, 'header', 'Join another ', (txt) => txt.split('*')[0]),
    withContent('sloganPtTwo', home, 'header', 'Join another ', (txt) => txt.split('*')[1]),
)(Header);
