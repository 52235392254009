const user = ({ user }) => user.user;
const userInfo = ({ user }) => user.userInfo;
const signInChecked = ({ user }) => user.signInChecked;
const userInfoMissing = ({ user }) => user.userInfoMissing;
const signOnForm = ({ user }) => user.signOnForm;
const recaptchaSolvedResult = ({ user }) => user.recaptchaSolvedResult;

export {
    user,
    userInfo,
    signInChecked,
    userInfoMissing,
    signOnForm,
    recaptchaSolvedResult,
};
